






















import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import SearchBar from "@/components/search-bar.vue";
import EmailsTable from "@/components/emails/EmailsTable.vue"
export default Vue.extend({
  components: {
    Layout,
    SearchBar,
    EmailsTable
  },
  created(){
    if(this.emails.length<1){
      this.fetchEmails({page:1})
    }
  },
  computed:{
    tempMails(){
      return this.emails.map(item=>{
        return {
          ...item,
          languages: [
            {
              lang: 'English',
              translated: false,
              translation_changed: false,
            },
            {
              lang: 'Slovakia',
              translated: true,
              translation_changed: false,
            },
          ],
        }
      })
    }
  },
  methods:{
    getSearchValue(val){

      this.searchText=val
    }
  },
  data() {
    return {
      showModal: false,
      title: "Emails",
      searchText:''
      // az_emails: [
      //   {
      //       id: 1,
      //       title: "Missing Company Logo",
      //       languages: [
      //           {
      //               lang: 'English',
      //               translated: true,
      //               translation_changed: false,
      //           },
      //           {
      //               lang: 'Slovakia',
      //               translated: true,
      //               translation_changed: false,
      //           },
      //       ],
      //   },
      //   {
      //       id: 2,
      //       title: "Missing Company Logo",
      //       languages: [
      //           {
      //               lang: 'English',
      //               translated: false,
      //               translation_changed: false,
      //           },
      //           {
      //               lang: 'Slovakia',
      //               translated: true,
      //               translation_changed: false,
      //           },
      //       ],
      //   },
      //   {
      //       id: 3,
      //       title: "Missing Company Logo",
      //       languages: [
      //           {
      //               lang: 'English',
      //               translated: true,
      //               translation_changed: true,
      //           },
      //           {
      //               lang: 'Slovakia',
      //               translated: true,
      //               translation_changed: false,
      //           },
      //       ],
      //   },
      // ],
    };
  },
});
