




























import Vue from "vue";
import i18n from "@/i18n/i18n";
export default Vue.extend({
  props: {
    emailss: {
      type: Array,
      default: function() {
        return [];
      },
    },
    searchText:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
    }
  },
  computed:{
    loc_emails(){
      if(this.searchText!=''){
        return this.emailss.filter(item=>i18n.locale=='en' ? item.emailNameEn.toLowerCase().includes(this.searchText.toLowerCase()) : item.emailNameSk.toLowerCase().includes(this.searchText.toLowerCase()) )
      }
      else{
        return this.emailss
      }
    },

    fields(){
      return [
        {
          key: this.$i18n.locale == 'en' ? 'emailNameEn' : 'emailNameSk',
          label: this.$i18n.t("Email Title"),

        },
        {
          key: 'languages',
          label: 'Languages'
        },
        // {
        //     key: 'action',
        // },
      ]
    }
  }
});
